import { render, staticRenderFns } from "./App.vue?vue&type=template&id=22dece92&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/Users/milan/WebstormProjects/rosian-travel-deals-webapp-fromgit/weekend-travel-deals-webapp/webapp/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VContent } from 'vuetify/lib'
import { VDatePicker } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VList } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VNavigationDrawer } from 'vuetify/lib'
import { VSelect } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VToolbar } from 'vuetify/lib'
import { VToolbarSideIcon } from 'vuetify/lib'
import { VToolbarTitle } from 'vuetify/lib'
installComponents(component, {VApp,VBtn,VContainer,VContent,VDatePicker,VIcon,VImg,VLayout,VList,VListTile,VListTileTitle,VNavigationDrawer,VSelect,VSpacer,VToolbar,VToolbarSideIcon,VToolbarTitle})
